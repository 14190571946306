import { useState } from "react";
import { useEffect } from "react";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import Advice from "../../components/advice/advice";
import CustomSelect from "../../components/utils/CustomSelect";
import { adviceClient } from "../../network/lib/advice";
import AdviceBoxSkeleton from "../../components/skeletons/expert/adviceBoxSkeleton";

function AdvicePage() {
  const [opinionsType, setOpinionsType] = useState("WAIT");
  const [opinions, setOpinions] = useState(null);

  useEffect(() => {
    callAdvice();
  }, [opinionsType]);

  function callAdvice() {
    adviceClient.getAll({ filter: opinionsType }).then((res) => {
      console.log(res.data.opinions);
      setOpinions(res.data.opinions);
    });
  }

  return (
    <div id="advice">
      <div className="top-bar">
        <h2>Avis</h2>
        <div className="pagination">
          <Link to="#" className="link">
            Accueil
          </Link>
          <IoIosArrowForward color="#A7A7A7" />
          <Link to="#" className="link">
            Avis
          </Link>
        </div>
      </div>

      <div className="content-box">
        <div className="form-group">
          <div className="flex-row gap-2">
            <div className="radio-group">
              <input
                type="radio"
                id="user-type-expert"
                name="type"
                value="Avis client (Attente)"
                onChange={(e) => {
                  setOpinions(null);
                  setOpinionsType("WAIT");
                }}
                checked={opinionsType === "WAIT"}
              />
              <label htmlFor="user-type-expert">Avis client (Attente)</label>
            </div>
            <div className="radio-group">
              <input
                type="radio"
                id="user-type-expert"
                name="type"
                value="Avis Expert (Attente)"
                onChange={(e) => {
                  setOpinions(null);
                  setOpinionsType("ONLY_CLIENT");
                }}
                checked={opinionsType === "ONLY_CLIENT"}
              />
              <label htmlFor="user-type-expert">Avis Expert (Attente)</label>
            </div>
            <div className="radio-group">
              <input
                type="radio"
                id="user-type-client"
                name="type"
                value="Validé"
                onChange={(e) => {
                  setOpinions(null);
                  setOpinionsType("FULL");
                }}
                checked={opinionsType === "FULL"}
              />
              <label htmlFor="user-type-client">Avis Completement Validé</label>
            </div>
          </div>
        </div>

        {!opinions ? (
          <AdviceBoxSkeleton count={4} />
        ) : (
          opinions.map((advice, index) => (
            <Advice advice={advice} key={index} setOpinions={setOpinions} />
          ))
        )}
      </div>
    </div>
  );
}

export default AdvicePage;
