import StarRating from "../rating";
import Modal from "./../modal/modal";
import { useEffect, useState } from "react";
import { adviceClient } from "./../../network/lib/advice";
import { fireSuccessAlert } from "./../utils/Swal";
import { SplitButton } from "primereact/splitbutton";

function Advice(props) {
  const {
    approve,
    approveResponse,
    clientName,
    expertName,
    comment,
    id,
    idShort,
    response,
    score,
    time,
  } = props.advice;

  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState(response);
  // const [action, setAction] = useState("")

  const updateApproveAction = (action) => {
    const data = { opinionId: id, action };
    adviceClient.action
      .update(data)
      .then((res) => fireSuccessAlert(`Approved for ${action.slice(8)}`));
  };

  const approveButtonOptions = [
    {
      label: "Approve Client",
      icon: "pi pi-refresh",
      command: () => {
        updateApproveAction("APPROVE_CLIENT");
      },
    },
    {
      label: "Approve All",
      icon: "pi pi-refresh",
      command: () => {
        updateApproveAction("APPROVE_ALL");
      },
    },
  ];

  const handleEditFormSubmit = (e) => {
    e.preventDefault();

    adviceClient.update({ opinionId: id, message }).then(() => {
      setShowModal(false);
      fireSuccessAlert("Updated Successfully");
    });
  };

  const handleDeleteAdvice = (e) => {
    e.preventDefault();
    adviceClient.delete(id).then((res) => {
      props.setOpinions((prevState) =>
        prevState.filter((advice) => advice.id !== id)
      );
      fireSuccessAlert("Deletion successful");
    });
  };

  const handleApproveDefault = (e) => {
    e.preventDefault();
    updateApproveAction("APPROVE_ALL");
  };

  return (
    <>
      <Modal
        title="Modifier le réponse"
        show={showModal}
        onClose={() => setShowModal(false)}
        width="40%"
      >
        <form
          className="experts-modal-form flex-column gap-1"
          onSubmit={handleEditFormSubmit}
        >
          <label>Message</label>
          <input
            type="text"
            onChange={(e) => setMessage(e.target.value)}
            value={message}
          />
          <button type="submit" className="gray-button">
            Soumettre
          </button>
        </form>
      </Modal>

      <div className="advice">
        <div className="top-bar-advice">
          <h3>
            {idShort} - {expertName} <span className="purple-text">{}</span>
          </h3>
          <StarRating rating={score} />
        </div>
        <div className="second-bar-advice">
          <div>
            <span className="green-text">{clientName}</span>
            <span className="grey-text">{comment}</span>
          </div>
          <span className="commentaire">
            Commentaire du {time} {`(Consultation du ${time})`}
          </span>
        </div>
        <hr />
        <div className="alert-box">
          <span>{response}</span>
        </div>
        <div className="btn-group-advice">
          <button className="modify" onClick={(e) => setShowModal(true)}>
            Modifier
          </button>
          <button className="reject" onClick={handleDeleteAdvice}>
            Rejeter
          </button>
          <SplitButton
            label="Approve All"
            icon="pi pi-check-circle"
            onClick={handleApproveDefault}
            model={approveButtonOptions}
          />
          {/* <button className="publish">Publier</button> */}
        </div>
      </div>
    </>
  );
}

export default Advice;
