import axios from "axios";
import { useEffect, useState } from "react";
import AdviceBox from "../../components/expert/adviceBox";
import AdviceBoxSkeleton from "../../components/skeletons/expert/adviceBoxSkeleton";
import { userClient } from './../../network/lib/user';

function AdviceExpert() {
  const [userOpinionData, userOpinionDataSet] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    userClient.expert.opinion.getAll()
      .then((resp) => {
        console.log(resp.data.opinions)
        userOpinionDataSet(resp.data.opinions);
      })
      .then(() => setIsLoading(false))
      .catch((error) => {
        //window.location.href = "https://vooxhub.com";
      });
  }, []);

  const renderWithData = () => {
    return userOpinionData.map((elem, i) => {
      console.log(elem)
      return (
        <AdviceBox
          key={i}
          id={elem["id"]}
          idShort={elem["idShort"]}
          score={elem["score"]}
          title={
            " (" +
            (elem["opinionStatus"] === "FULL"
              ? "Publié"
              : "En Attente") +
            ") - (Réponse " +
            (elem["opinionStatus"] === "FULL" || elem["opinionStatus"] === "ONLY_CLIENT"
              ? "Publié"
              : "En Attente") +
            ")"
          }
          alert={elem["response"]}
        >
          Par {elem["clientName"]} - {elem["time"]}
          <br />
          {elem["comment"]}
        </AdviceBox>
      );
    });
  };

  return (
    <div id="adviceExpert">
      <h2>Mes Avis</h2>
      {isLoading ? <AdviceBoxSkeleton count={4} /> : renderWithData()}
    </div>
  );
}

export default AdviceExpert;
